.noMargin {
    margin: 0;
}

.minorPadding {
    padding: 10px;
}

.center {
    margin: 0 auto;
}

.postWidth {
    width: 90%;
}

.blackThinBorder {
    border-style: solid;
    border-color: black;
    border-width: 2px;
}

.date {
    text-align: right;
}

.avatar {
    float: left;
    height: 50px;
    width: 50px;
}

.whiteBackground {
    background-color: white;
}
